import mask from '../assets/icons/faceMask.png';
import handSanitizer from '../assets/icons/gel.png';
import gloves from '../assets/icons/gloves2.png';
import compass from '../assets/icons/location.png';

export const ICONS = {
  mask,
  handSanitizer,
  gloves,
  compass,
};
