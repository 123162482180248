export const palette = {
  themePrimary: "#4c4c4d",
  themeLighterAlt: "#f8f8f8",
  themeLighter: "#e2e2e2",
  themeLight: "#c9c9c9",
  themeTertiary: "#939394",
  themeSecondary: "#616162",
  themeDarkAlt: "#444445",
  themeDark: "#3a3a3a",
  themeDarker: "#2a2b2b",
  neutralLighterAlt: "#faf9f8",
  neutralLighter: "#f3f2f1",
  neutralLight: "#edebe9",
  neutralQuaternaryAlt: "#e1dfdd",
  neutralQuaternary: "#d0d0d0",
  neutralTertiaryAlt: "#c8c6c4",
  neutralTertiary: "#a19f9d",
  neutralSecondary: "#605e5c",
  neutralPrimaryAlt: "#3b3a39",
  neutralPrimary: "#323130",
  neutralDark: "#201f1e",
  black: "#000000",
  white: "#ffffff",
};
